import {Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, IconButton, MenuItem, Modal, Paper, Popover, Select, Stack, Switch, TextField, Tooltip, Typography, debounce, } from "@mui/material";
import { FieldArray, FieldArrayRenderProps, FormikErrors, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as yup from "yup";
import { ChecklistQuestion, ChecklistVersionName, InspectionChecklistDto, InspectionChecklistSection, InspectionChecklistVersion, QuestionDto, QuestionType, SectionRepeatType, SectionRepeatTypeString, VersionRepeatType, VersionRepeatTypeString } from "src/dtos/Checklists/MaintenanceChecklist.dto";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import "./styles.scss";
import AttractionService from "src/Services/Resources/AttractionService";
import ErrorHandlingSnackbar, {MessageItem, } from "src/components/errorHandlingSnackbar";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import NewQuestionForm from "src/components/ChecklistCreationComponents/NewQuestionForm/NewQuestionForm";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import QRCode from "react-qr-code";
import { ChildEntity } from "src/dtos/EntityChild.dto";
import { Status } from "src/dtos/Statuses";
import InspectionChecklistVersionService from "src/Services/Checklists/Inspections/InspectionChecklistVersionService";
import { useNavigate, useParams } from "react-router-dom";
import InspectionChecklistService from "src/Services/Checklists/Inspections/InspectionChecklistService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from "@mui/lab";
import ShowDifferences from "src/components/ShowDifferences/ShowDifferences";
import RepeatIcon from '@mui/icons-material/Repeat';


const validationSchema = yup.object({
  versionLabel: yup.string().max(50, "Version name cannot exceed 50 characters").required("Version Name is required"),
  versionDescription: yup.string().max(1000, "Version description cannot exceed 1000 characters").required("Version Description is required"),
  sections: yup.array()
    .of(
      yup.object().shape({
        label: yup.string().max(50, "Section title cannot exceed 50 characters").required("Section title required"),
        sublabel: yup.string().max(255, "Section subtitle cannot exceed 255 characters").notRequired(),
        repeatingComponentSet: yup.object().shape({
          id: yup.number(),
          label: yup.string()
        }).notRequired(),
        sectionRepeatType: yup.number().when(['repeatingComponentSet'], ([repeatingComponentSet]) => {
          if(repeatingComponentSet == null)
            return yup.number().notRequired()
          return yup.number().required("Section Repeat Type is a required field")
        }),
        questions: yup.array()
          .of(
            yup.object().shape({
              number: yup.number().positive().notRequired(),
              label: yup.string().max(255, "Question title cannot exceed 255 characters").required("Question title requried"),
              type: yup.number().required("Entry Type is a required field"),
              additionalInformation: yup.string().max(255, "Question additional information cannot exceed 255 characters").notRequired(),
              minAcceptableValue: yup.number().when(['type'], ([type]) => {
                if(type === QuestionType.Number)
                  return yup.number()
                  .required("Minimum Acceptable Value is a required field")
                return yup.number()
                  .notRequired()
              }),
              maxAcceptableValue: yup.number().when(['type'], ([type]) => {
                if(type === QuestionType.Number)
                  return yup.number()
                  .required("Maximum Acceptable Value is a required field")
                return yup.number()
                  .notRequired()
              }),
              inputUnits: yup.number().when(['type'], ([type]) => {
                if(type === QuestionType.Number)
                  return yup.string()
                  .max(20, "Units cannot be more than 20 characters")
                  .required("Units is a required field")
                return yup.string()
                  .notRequired()
              }),
            })
          )
      })
    )
});

const questionTypesWithoutNumber: QuestionType[] = [QuestionType.Alert] // These question types are skipped when determining the question number

function InspectionsChecklistCreation() {
  const [formValues, setFormValues] = useState<InspectionChecklistVersion>(InspectionChecklistVersionService.GetDefaultVersionValues());
  const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined, });
  const [showChanges, setShowChanges] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [inspectionValues, setInspectionValues] = useState<InspectionChecklistDto>(InspectionChecklistService.GetDefaultValues())

  const [attractionId, setAttractionId] = useState<string>();
  const [componentsList, setComponentsList] = useState<ChildEntity[]>([]);
  const [versionNames, setVersionNames] = useState<ChecklistVersionName[]>([])
  const [componentSetList, setComponentSetList] = useState<ChildEntity[]>([]);
  const [anchorElCopyVersion, setAnchorElCopyVersion] = useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate()
  const {inspectionId, versionId} = useParams();
  const urlVersionIdRegex = new RegExp(`/${versionId}$`)
  const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      save(values);
    }
  });

  const handleBackClick = () => {
    //remove id param from end of the url
    if(formik.dirty && formik.values.status === Status.Draft){
      if(!window.confirm("You have unsaved changes, are you sure you want to go back?")){
        return
      }
    } 

    navigate({ pathname: window.location.pathname.replace(urlVersionIdRegex, '') });
    
  }

  const handleCopyVersionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElCopyVersion(event.currentTarget);
  }

  const handleStartNewClick = () => {
    formik.setFieldValue("sections", [InspectionChecklistVersionService.GetDefaultSectionValues()])
  }

  useEffect(()=>{ 
    const fetchInspectionChecklist = async () => {
      try {
        if(inspectionId !== undefined && guidRegex.test(inspectionId)){
          const data = await InspectionChecklistService.Get(inspectionId);
          setInspectionValues(data)
          setAttractionId(data.attraction?.id);
        }
      } catch (error: any) {
        setMessageItem({error: error})
      }
    }

    const fetchChecklistVersion = async () => {
      try {
        if(versionId !== undefined && guidRegex.test(versionId)){
          const data = await InspectionChecklistVersionService.Get(versionId);
          setFormValues(data);
        } else {
          //navigate to create page
          navigate({ pathname: window.location.pathname.replace(urlVersionIdRegex, '/new')})
        }
      } catch (error: any) {
        if(error.response !== undefined && error.response.status === 404){
          //navigate to create page
          navigate({ pathname: window.location.pathname.replace(urlVersionIdRegex, '/new')})
        }else {
          setMessageItem({error: error})
        }
      } 
    }

    const fetchVersionNames = async () => {
      try {
        if(inspectionId !== undefined && guidRegex.test(inspectionId)){
          const data = await InspectionChecklistVersionService.GetNames(inspectionId);
          setVersionNames(data);

          //if there are no previous versions then user shouldnt be able to copy from another version
          if(data.length === 0){
            formik.setFieldValue("sections", [InspectionChecklistVersionService.GetDefaultSectionValues()])
          }
        }
      } catch (error: any) {
        setMessageItem({error: error})
      }
    }

    fetchInspectionChecklist();
    fetchChecklistVersion();
    fetchVersionNames();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionId, versionId])
  

  const save = async (values: InspectionChecklistVersion) => {
    setLoading(true)
    if(!formEditable){
      setMessageItem({errorMessage:"You cannot save or publish a checklist unless it is a draft"})
    }

    if(inspectionId !== undefined && guidRegex.test(inspectionId)){
      values.inspectionChecklistId = inspectionId
    }
    
    //Update sections sort order
    const updatedSections = values.sections.map((section, index) => ({
      ...section, 
      sortOrder: index,
    }));

    values.sections = updatedSections;

    //Update userSelected components
    values.userSelectedComponent = null;
    if(values.repeatType && [VersionRepeatType.UserSelected, VersionRepeatType.UserSelectedOneOnly].includes(values.repeatType) && values.repeatingComponentSet){
      values.userSelectedComponent = values.repeatingComponentSet
    } else {
      values.sections.forEach(section => {
        if(section.sectionRepeatType === SectionRepeatType.UserSelected && section.repeatingComponentSet){
          values.userSelectedComponent = section.repeatingComponentSet
        }
        section.questions.forEach(question => {
          if(question.repeatOnComponentSetUserSelected && question.relatedComponent){
            values.userSelectedComponent = question.relatedComponent
          }
        })
      })
    } 

    console.log(values)
    try {
      if(versionId === "new"){
        //create
        const response = await InspectionChecklistVersionService.Create(values);
        if(response.status >= 200 && response.status <= 299){
          setMessageItem({successMessage: "Version Created Successfully!"})
        }

        //Update url
        navigate({pathname: window.location.pathname.replace(urlVersionIdRegex, `/${response.data.id}`)})
      } else if (versionId !== undefined){
        //Update
        const response = await InspectionChecklistVersionService.Update(versionId, values)
        if(response.status >= 200 && response.status <= 299){
          setMessageItem({successMessage: "Version Updated Successfully!"})
        }
        setFormValues(values)
      }
    } catch(error: any){
      setMessageItem({error: error})
    } finally {
      setLoading(false)
    }
  };

  const handlePublishClick = () => {
    setPublishAlertDialogOpen(true);
  }

  const [publishAlertDialogOpen, setPublishAlertDialogOpen] = useState(false);

  function PublishAlertDialog() {
    const handleClick = () => {
      handlePublish();
      setPublishAlertDialogOpen(false);
    }
    return (
      <Dialog
        open={publishAlertDialogOpen}
        onClose={()=> setPublishAlertDialogOpen(false)}
      >
        <DialogTitle>
        Publish Checklist Version?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to publish this version?
            Once it has been published it will become available to complete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPublishAlertDialogOpen(false)}>Cancel</Button>
          <Button variant={"contained"} onClick={handleClick}>Publish</Button>
        </DialogActions>
      </Dialog>
    )
  }
  const handlePublish = async () => {
    setLoading(true);
    //if there are unsaved changes, save the checklist before publishing
    if(formik.dirty){
      handleSaveClick();
    }

    //set as published
    try {
      if(versionId !== undefined && guidRegex.test(versionId)){
        const response = await InspectionChecklistVersionService.Publish(versionId);
        if(response.status >= 200 && response.status <= 299){
          setMessageItem({successMessage: "Version Published Successfully!"})
          formik.setFieldValue("status", Status.Published);
        }
      }
    } catch (error: any) {
      setMessageItem({error: error})
    } finally {
      setLoading(false)
    }    
  }

  function DemoQRCode(){
    const demoUrl = `${window.location.origin}/user/InspectionDemo/${versionId}`
    return (
      <Modal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
      >
        <div className="modalBox">
          <Typography variant="h5" sx={{paddingBottom: "2rem"}}>Checklist Demonstration</Typography>
          <div style={{cursor:"pointer"}} onClick={() => window.open(demoUrl)}>
            <Stack>
              <QRCode value={demoUrl}/>
              <Button variant="contained" sx={{marginTop:"1rem"}}>Open Demonstration</Button>
            </Stack>
          </div>
          
        </div>
      </Modal>
    )
  }

  const formEditable = formik.values.status === Status.Draft;
  const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm],)

  useEffect(() => {
    debouncedValidate(formik.values);
  }, [formik.values, debouncedValidate]);

  // #region Question and Section Handlers
  const [touchedQuestion, setTouchedQuestion] = useState<String>("");
  const [touchedSection, setTouchedSection] = useState<string>("");
  const [movingQuestion, setMovingQuestion] = useState<{sectionIndex: number, questionIndex: number} | null>(null);
  const [hideQuestions, setHideQuestions] = useState(false);
  const [ghostLocation, setGhostLocation] = useState("");

  const handleQuestionClick = (sectionIndex: number, questionIndex: number) => {
    if(!formEditable){
      return
    }
    if(movingQuestion === null){
      setTouchedQuestion(`${sectionIndex}.${questionIndex}`); 
      setTouchedSection("")
    } else {
      moveQuestion(movingQuestion.sectionIndex, sectionIndex, movingQuestion.questionIndex, questionIndex)
    }
  }
  
  const UpdatedQuestionNumbers = (sectionQuestions: QuestionDto[]): QuestionDto[] => {
    let currentNumber = 1;
    sectionQuestions.forEach((question, questionIndex) => {
      if(!questionTypesWithoutNumber.includes(question.type)){
        sectionQuestions[questionIndex] = {...question, number: currentNumber, sortOrder: questionIndex}
        currentNumber += 1;
      } else {
        sectionQuestions[questionIndex] = {...question, sortOrder: questionIndex, number: undefined}
      }
      
    });
    return sectionQuestions;
  }

  const moveQuestion = (oldSectionIndex: number, newSectionIndex: number, oldQuestionIndex: number, newQuestionIndex: number) => {
    const question = formik.values.sections[oldSectionIndex].questions[oldQuestionIndex];
    const above = ghostLocation.includes("Above");
    const calculatedQuestionIndex = newQuestionIndex + (above ? 0 : 1);

    ClearTouchedSectionAndQuestion();
    
    //within the same section
    if(oldSectionIndex === newSectionIndex) {
      const sectionQuestions = [...formik.values.sections[oldSectionIndex].questions];
      //insert new question
      sectionQuestions.splice(calculatedQuestionIndex, 0, question);
      //remove old question
      const calculatedOldQuestionIndex = oldSectionIndex !== newSectionIndex ? oldQuestionIndex : oldQuestionIndex < calculatedQuestionIndex ? oldQuestionIndex : oldQuestionIndex + 1;
      sectionQuestions.splice(calculatedOldQuestionIndex, 1);
      //Update question numbers
      const updatedSectionQuestions = UpdatedQuestionNumbers(sectionQuestions);
      //set new values
      formik.setFieldValue(`sections[${oldSectionIndex}].questions`, updatedSectionQuestions);
      return
    } 
    
    //different sections
    //remove question from old section
    removeQuestion(oldSectionIndex, oldQuestionIndex)

    //add question to new section
    const newSectionQuestions = [...formik.values.sections[newSectionIndex].questions]
    newSectionQuestions.splice(calculatedQuestionIndex, 0, question);
    //update new section question numbers
    const updatedNewSectionQuestions = UpdatedQuestionNumbers(newSectionQuestions);
    //set new values
    formik.setFieldValue(`sections[${newSectionIndex}].questions`, updatedNewSectionQuestions);       
  }

  const addNewQuestion = (sectionIndex: number, questionIndex:number) => {

    const newQuestion = InspectionChecklistVersionService.GetDefaultQuestionValues()
    const sectionQuestions = [...formik.values.sections[sectionIndex].questions];
    //insert new question
    sectionQuestions.splice(questionIndex, 0, newQuestion);
    //update question numbers
    const updatedSectionQuestions = UpdatedQuestionNumbers(sectionQuestions)
    //set new values
    formik.setFieldValue(`sections[${sectionIndex}].questions`, updatedSectionQuestions);
    //set new question as the touched question
    setTouchedQuestion(`${sectionIndex}.${questionIndex}`);
  }

  const removeQuestion = (sectionIndex: number, questionIndex: number) => {

    const sectionQuestions = [...formik.values.sections[sectionIndex].questions]; 
    //remove question
    sectionQuestions.splice(questionIndex, 1);

    //if the user removed the last question then replace questions with new question
    if(sectionQuestions.length === 0){
      const newQuestion = InspectionChecklistVersionService.GetDefaultQuestionValues();
      formik.setFieldValue(`sections[${sectionIndex}].questions`, [newQuestion])
      setTouchedQuestion(`${sectionIndex}.${questionIndex}`);
      return
    }
    //Update question numbers
    const updatedSectionQuestions = UpdatedQuestionNumbers(sectionQuestions);
    //set new values
    formik.setFieldValue(`sections[${sectionIndex}].questions`, updatedSectionQuestions);
    //clear touched question
    setTouchedQuestion("");
  }

  const addSection = (newSectionIndex: number, sectionArrayHelpers: FieldArrayRenderProps) => {
    const newSection = InspectionChecklistVersionService.GetDefaultSectionValues();
    sectionArrayHelpers.insert(newSectionIndex, newSection)
  }

  const handleMoveSection = (oldIndex: number, newIndex: number, sectionArrayHelpers: FieldArrayRenderProps) => {
    const sectionsQuantity = formik.values.sections.length;
    if(newIndex < 0 || newIndex > sectionsQuantity -1){
      return
    }
    sectionArrayHelpers.move(oldIndex, newIndex);
  }

  const removeSection = (sectionIndex: number, sectionArrayHelpers: FieldArrayRenderProps) => {
    if(window.confirm("Are you sure you wish to delete this section? This action is not reversible.")){
      sectionArrayHelpers.remove(sectionIndex);
      if(formik.values.sections.length <= 1){
        addSection(0, sectionArrayHelpers);
      }
    }
  }

  const ClearTouchedSectionAndQuestion = () => {
    setTouchedQuestion("");
    setTouchedSection("");
    setMovingQuestion(null);
    setGhostLocation("");
  }
  // #endregion



  useEffect(() => {
    if(attractionId !== undefined){
      const fetchRideComponents = async () => {
        try {
          const data = await AttractionService.GetComponents(attractionId);
          GenerateComponentsList(data)
        } catch (error: any) {
          setMessageItem({error: error});
        }
      }
      fetchRideComponents();
    } else {
      setComponentsList([]);
    }
   
  }, [attractionId])
  
  //used for each questions ride component autocomplete.
  const GenerateComponentsList = (components: ChildEntity) => {
    let newComponentsList: ChildEntity[] = [];
    
    //create all components list
    const allComponents = (parentComponent: ChildEntity) => {
      if(parentComponent.children && parentComponent.children.length > 0){
          //add all children to new list
          parentComponent.children.forEach((child) => (
              newComponentsList.push({...child, parentLabel: parentComponent.label})
          ))
          
          //recursively checks for more sub children
          parentComponent.children.forEach((child) => (
              allComponents(child)
          ))

      }
    }
    allComponents(components);
    setComponentsList(newComponentsList);

    let newComponentSetsList: ChildEntity[] =[];
    //Creates a list of only the components that have children
    const allComponentSets = (parentComponent: ChildEntity) => {
      if(parentComponent.children && parentComponent.children.length > 0){
        //if the children have children add to component sets list
        parentComponent.children.forEach((child) => {
          if(child.children && child.children.length > 0){
            newComponentSetsList.push(child)
          }
        })

        //recursively checks for more sub children
        parentComponent.children.forEach((child) => (
          allComponentSets(child)
        ))

      }
    }
    allComponentSets(components);
    setComponentSetList(newComponentSetsList);
  }  

  const [formErrors, setFormErrors] = useState<JSX.Element | null>(null)
  const handleSaveErrors = () => {

    let errors: JSX.Element[] = [];
    let sectionsErrors = formik.errors.sections

    //Loop sections for errors
    if(sectionsErrors === undefined){
      setFormErrors(null);
      return
    }
    
    for(let i = 0; i < sectionsErrors.length; i++) {
      const sectionErrors = sectionsErrors[i] as FormikErrors<InspectionChecklistSection>;
      if(sectionErrors === undefined){
        continue
      }

      const sectionAllErrors: JSX.Element[] = [];
      
      sectionAllErrors.push(<Typography variant="h6">{`Section: ${formik.values.sections[i].label === "" ? `Number ${i+1}` : formik.values.sections[i].label}`}</Typography>)
      if(sectionErrors.label !== undefined){
        sectionAllErrors.push(<Typography color="red">{sectionErrors.label}</Typography>)
      }
      if(sectionErrors.sublabel !== undefined){
        sectionAllErrors.push(<Typography color="red">{sectionErrors.sublabel}</Typography>)
      }
      if(sectionErrors.repeatingComponentSet !== undefined)
        sectionAllErrors.push(<Typography color="red">{sectionErrors.repeatingComponentSet}</Typography>)
      if(sectionErrors.sectionRepeatType !== undefined)
        sectionAllErrors.push(<Typography color="red">{sectionErrors.sectionRepeatType}</Typography>)
      
      
      errors = [...errors, ...sectionAllErrors]

      const questionsErrors = sectionErrors.questions;
      if(questionsErrors !== undefined){
        for(let j = 0; j < questionsErrors.length; j++){
          const questionErrors = questionsErrors[j] as FormikErrors<ChecklistQuestion>;
          if(questionErrors === undefined){
            continue
          }

          const questionAllErrors: JSX.Element[] = [];
          if(questionErrors.label !== undefined){
            questionAllErrors.push(<Typography color="red">{`Question ${j+1}: ${questionErrors.label}`}</Typography>)
          }
          if(questionErrors.additionalInformation !== undefined){
            questionAllErrors.push(<Typography color="red">{`Question ${j+1}: ${questionErrors.additionalInformation}`}</Typography>)
          }
          if(questionErrors.minAcceptableValue !== undefined){
            questionAllErrors.push(<Typography color="red">{`Question ${j+1}: ${questionErrors.minAcceptableValue}`}</Typography>)
          }
          if(questionErrors.maxAcceptableValue !== undefined){
            questionAllErrors.push(<Typography color="red">{`Question ${j+1}: ${questionErrors.maxAcceptableValue}`}</Typography>)
          }
          if(questionErrors.inputUnits !== undefined){
            questionAllErrors.push(<Typography color="red">{`Question ${j+1}: ${questionErrors.inputUnits}`}</Typography>)
          }
          errors = [...errors, ...questionAllErrors]
        }
      }

    }
    setFormErrors(<div>{errors}</div>)
    if(errors.length > 0){
      setMessageItem({errorMessage: "Form validation errors please view errors at bottom of page"})
    }
    
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSaveClick = () => {
    if(formEditable){
      if(formik.isValid){
        formik.handleSubmit()
        setFormErrors(null)
      } else {
        formik.validateForm();
        handleSaveErrors()
      }
    } 
  }
  
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if(event.repeat){
        //prevent function from firing continuously
        return
      }
      if(event.key === "Escape")
      {
        ClearTouchedSectionAndQuestion();
      }
      if((event.key === "S" || event.key === "s") && event.ctrlKey){
        event.preventDefault(); // Stop page from downloading
        handleSaveClick();
      }
    }

    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = true; //Legacy support
    }
    // Add event listener when component mounts
    document.addEventListener('keydown', handleKeyDown);
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [formEditable, formik, handleSaveClick]);
  

  function CopyVersionSelector() {
    
    const handleClose = () => {
      setAnchorElCopyVersion(null)
    }
    const [selectedVersion, setSelectedVersion] = useState<ChecklistVersionName | null>(null)
    
    const handleClick = async () => {
      //Get copy
      try {
        if(selectedVersion && selectedVersion.id){
          const copy: InspectionChecklistVersion = await InspectionChecklistVersionService.GetCopy(selectedVersion.id);
          formik.setValues(copy)
        }
      } catch (error: any) {
        setMessageItem({error: error})
      }
    }

    const open = Boolean(anchorElCopyVersion);
    return (
      <>
        <Popover
          open={open}
          anchorEl={anchorElCopyVersion}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        
        >
          <Box>
            <Stack sx={{padding: "0.5rem"}} spacing={2}>
              <Typography sx={{textAlign:"center"}} variant="h6">Select Version</Typography>
              <Autocomplete
                disablePortal={false}
                id="version"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option.versionLabel ? option.versionLabel : "")}
                options={versionNames}
                size="small"
                sx={{ width:" 15rem"}}
                onChange={(_, value) => setSelectedVersion(value)}
                value={selectedVersion}
                renderInput={(params) => 
                <TextField {...params} />}
              />
              <div  style={{display:"flex", justifyContent:"center"}}>
                <Button onClick={handleClick}>Copy Version</Button>
              </div>
              
            </Stack>
            
          </Box>
        </Popover>
      </>
    )
  }

  function Section({sectionIndex, sectionArrayHelpers}: {sectionIndex: number, sectionArrayHelpers: FieldArrayRenderProps}){
    const handleSectionClick = (event: any) => {
      if(formEditable){
        event.stopPropagation(); 
        setTouchedQuestion(""); 
        setTouchedSection(`${sectionIndex}`)
      }
    }
    
    const [sectionLabel, setSectionLabel] = useState(formik.values.sections[sectionIndex].label)
    const [sectionSubLabel, setSectionSubLabel] = useState(formik.values.sections[sectionIndex].sublabel)

    const previousSectionLabel = formik.values.sections[sectionIndex].previousSection?.label;
    const previousSectionSublabel = formik.values.sections[sectionIndex].previousSection?.sublabel;
    const sectionRepeatingComponentSet = formik.values.sections[sectionIndex].repeatingComponentSet;
    const previousSectionRepeatingComponentSet = formik.values.sections[sectionIndex].previousSection?.repeatingComponentSet;

    return(
      <Stack direction="row" key={sectionIndex} sx={{display: "flex", alignItems: "center"}}>
        <Paper sx={{width: "100%"}}>
          <div onClick={handleSectionClick}>
            {touchedSection === `${sectionIndex}` ? (
              <>
              <Stack sx={{padding: "1rem"}} spacing={2}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    id={`sections[${sectionIndex}].label`}
                    name={`sections[${sectionIndex}].label`}
                    label="Section Title"
                    value={sectionLabel}
                    onChange={(e) => setSectionLabel(e.target.value)}
                    onKeyDown={event => {
                      if(event.key === 'Enter'){
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        formik.setFieldValue(`sections[${sectionIndex}].label`, sectionLabel);
                      }
                    }}
                    onBlur={() => formik.setFieldValue(`sections[${sectionIndex}].label`, sectionLabel)}
                    variant="standard"
                    size="medium"
                    sx={{width:"100%"}}
                  />
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Tooltip title="Delete Section">
                      <IconButton sx={{color: "Crimson"}} onClick={() => removeSection(sectionIndex, sectionArrayHelpers)}>
                        <DeleteOutlineOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                  </div>
                  
                </Stack>
                <TextField
                    id={`sections[${sectionIndex}].sublabel`}
                    name={`sections[${sectionIndex}].sublabel`}
                    label="Section Subtitle"
                    value={sectionSubLabel}
                    onChange={(e) => setSectionSubLabel(e.target.value)}
                    onBlur={() => formik.setFieldValue(`sections[${sectionIndex}].sublabel`, sectionSubLabel)}
                    variant="standard"
                    size="small"
                    multiline
                    maxRows={2}
                  />
                <Stack direction="row" spacing={4}>
                  <div>
                    <QuestionTitle title="Repeating on Component Set" infoText="Repeat this section per component within the component set."/>
                    <Autocomplete
                      disablePortal
                      id="repeatingComponentSet"
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => (option.label ? option.label : "")}
                      options={componentSetList}
                      size="small"
                      sx={{ width: "20rem"}}
                      value={formik.values.sections[sectionIndex].repeatingComponentSet ?? null}
                      onChange={(_, value: any) => {
                        formik.setFieldValue(
                          `sections[${sectionIndex}].repeatingComponentSet`,
                          value ? value : null
                        )
                        formik.setFieldValue(`sections[${sectionIndex}].repeatingComponentSetId`, value?.id ?? null)
                        if(value === null){
                          formik.setFieldValue(`sections[${sectionIndex}].sectionRepeatType`, null);
                        } 
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            name=""
                            size="small"
                            variant="standard"
                          />
                      )}
                    />
                  </div>
                  {(formik.values.sections[sectionIndex].repeatingComponentSet !== null) && (
                     <div>
                     <QuestionTitle title="Repeating Section Type" required infoText="Toggled ON: Only components within the set selected by the user at the begining of the inspection will have a section. Togged OFF: ALL components within the component set will have a section."/>                   
                     <Select
                      id="sectionRepeatType"
                      name="sectionRepeatType"
                      value={formik.values.sections[sectionIndex].sectionRepeatType}
                      onChange={(event) => formik.setFieldValue(`sections[${sectionIndex}].sectionRepeatType`, event.target.value)}
                      variant="standard"
                      size="small"
                      sx={{width:"10rem"}}
                     >
                      <MenuItem value={SectionRepeatType.AlwaysRepeat}>{SectionRepeatTypeString[SectionRepeatType.AlwaysRepeat]}</MenuItem>
                      <MenuItem value={SectionRepeatType.UserSelected}>{SectionRepeatTypeString[SectionRepeatType.UserSelected]}</MenuItem>
                      <MenuItem value={SectionRepeatType.Grouped}>{SectionRepeatTypeString[SectionRepeatType.Grouped]}</MenuItem>
                     </Select>
                   </div>
                  )}
                </Stack>   
                <Divider variant="fullWidth"/>
                
              </Stack>
              </>
            ):(
              <>
                {showChanges ? (
                  <Stack sx={{padding:"1rem"}}>
                      <ShowDifferences 
                        originalContent={previousSectionLabel ?? ""}
                        newContent={formik.values.sections[sectionIndex].label}
                        variant="h5"
                      />
                      <ShowDifferences 
                        originalContent={previousSectionSublabel ?? ""}
                        newContent={formik.values.sections[sectionIndex].sublabel ?? ""}
                      />
                      <Stack direction="row" spacing={1} style={{paddingTop: "0.25rem", display: "flex", width:"max-content", borderTop:"0.1rem solid", borderColor: "lightgrey"}}>
                        
                        {(previousSectionRepeatingComponentSet || sectionRepeatingComponentSet) && <RepeatIcon/>}
                        <ShowDifferences
                          originalContent={previousSectionRepeatingComponentSet ? previousSectionRepeatingComponentSet.label : ""}
                          newContent={sectionRepeatingComponentSet ? sectionRepeatingComponentSet.label : ""}
                        />   
                      </Stack>
                  </Stack>
                  
                
                  ) : (
                    <Stack sx={{padding:"1rem"}}>
                      <Typography variant="h5" >{formik.values.sections[sectionIndex].label.length > 0 ? formik.values.sections[sectionIndex].label : "New Section"}</Typography>
                      <Typography>{formik.values.sections[sectionIndex].sublabel}</Typography>
                      {formik.values.sections[sectionIndex].repeatingComponentSet !== null && (
                        <div style={{paddingTop:"0.5rem"}}>
                          <Stack direction="row" spacing={1} style={{paddingTop: "0.25rem", display: "flex", width:"max-content", borderTop:"0.1rem solid", borderColor: "lightgrey"}}>
                            <RepeatIcon/>
                            <Typography sx={{display: "flex"}}>{formik.values.sections[sectionIndex].repeatingComponentSet?.label}</Typography>
                          </Stack>
                        </div>
                      )}
                    </Stack>
                  )

                  }
              </>
              
              
              
            )}

          </div>
          {!hideQuestions && (
            
            <FieldArray
              name={`sections[${sectionIndex}].questions`}
              render={(questionArrayHelpers: FieldArrayRenderProps) => {
                return (
                  <Stack sx={{marginLeft: "1rem", marginRight: "1rem"}} spacing={2} direction="column">
                    {formik.values.sections[sectionIndex].questions.map((question, questionIndex) => (
                        <Question
                          key={questionIndex}
                          questionIndex={questionIndex}
                          sectionIndex={sectionIndex}
                          previousQuestion={question.previousQuestion}
                        />
                    ))}
                  </Stack>
              )}
            }
            />
              
          )}
          {formEditable ? (
            <div style={{display: "flex", justifyContent:"center"}}>
              <Button onClick={() => addSection(sectionIndex+1, sectionArrayHelpers)}>
                <Typography>Add Section</Typography>
              </Button>
            </div>
          ) : (
            <div style={{padding:"0.5rem"}}></div>
          )}
            
        </Paper>
        {formEditable && (
          <Stack>
            <Button onClick={() => handleMoveSection(sectionIndex, sectionIndex-1, sectionArrayHelpers)}>
              <ArrowUpwardIcon/>
            </Button>
            <Button onClick={() => handleMoveSection(sectionIndex, sectionIndex+1, sectionArrayHelpers)}>
              <ArrowDownwardIcon/>
            </Button>
          </Stack>
        )}
        
      </Stack>
    )
  }

  type QuestionProps = {
    questionIndex: number,
    sectionIndex: number,
    previousQuestion: QuestionDto | undefined
}

function Question({questionIndex, sectionIndex, previousQuestion}: QuestionProps){

    const QuestionGhost = () => {
      const questionLabel = movingQuestion && formik.values.sections[movingQuestion.sectionIndex].questions[movingQuestion.questionIndex].label;

      return (
        movingQuestion != null ? (
          <Paper style={{opacity: 0.5}}>
            <Typography style={{ padding: "1rem"}}>{questionLabel && questionLabel.length > 0 ? questionLabel : "New Question"}</Typography>
          </Paper>
        ):(
          <></>
        )
      )
    }

    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if(divRef && divRef.current && movingQuestion !== null) {
        const handleMouseMove = (event: MouseEvent) => {
          const rect = divRef.current && divRef.current.getBoundingClientRect();
          const divTop = rect ? rect.top : 0;
          const divBottom = rect ? rect.bottom : 0;
          const mouseY = event.clientY;

          const mouseInTopHalf = mouseY < (divTop + divBottom) / 2
          const adjacentToMovingQuestion = 
            sectionIndex === movingQuestion.sectionIndex && //if mouse on question within moving questions section
            (questionIndex === movingQuestion.questionIndex || //if mouse on moving question
            (questionIndex - 1 === movingQuestion.questionIndex && mouseInTopHalf) || // if mouse is on the top half of the question directly below moving question
            (questionIndex + 1 === movingQuestion.questionIndex && !mouseInTopHalf)) //if mouse is on the bottom half of the question directly above moving question 

          if(adjacentToMovingQuestion){
            setGhostLocation(`${movingQuestion.sectionIndex}.${movingQuestion.questionIndex}`)
          }
          else if (mouseInTopHalf) {
            // Mouse is over the top half of the div/question
            setGhostLocation(`${sectionIndex}.${questionIndex}Above`)
          } else {
            //Mouse is over the bottom half of the div/question
            setGhostLocation(`${sectionIndex}.${questionIndex}Below`)
            
          }
        }

        const divRefCurrent = divRef.current;
        divRefCurrent.addEventListener('mousemove', handleMouseMove);

        return () => {
          divRefCurrent.removeEventListener('mouseover', handleMouseMove);
        };
      }
    }, [questionIndex, sectionIndex])

    return (
      <div 
        onClick={() => {handleQuestionClick(sectionIndex, questionIndex)}} 
        ref={divRef}
      >
        <Stack spacing={2}>
          {/*Hovering above */}
          {ghostLocation === `${sectionIndex}.${questionIndex}Above` && (
            <QuestionGhost/>   
          )}
          
          {!(movingQuestion?.questionIndex === questionIndex && movingQuestion.sectionIndex === sectionIndex) ? (
            <Paper>
              <NewQuestionForm
                formik={formik}
                sectionIndex={sectionIndex}
                questionIndex={questionIndex}
                activeQuestion={touchedQuestion === `${sectionIndex}.${questionIndex}`}
                showChanges={showChanges}
                previousQuestion={previousQuestion}
                setMovingQuestion={setMovingQuestion}
                setGhostLocation={setGhostLocation}
                addQuestion={() => addNewQuestion(sectionIndex, questionIndex +1)}
                removeQuestion={() => removeQuestion(sectionIndex, questionIndex)}
                components={componentsList}
                setMessageItem={setMessageItem}
                UpdatedQuestionNums={UpdatedQuestionNumbers}
              />
            </Paper>
          ): (ghostLocation === `${sectionIndex}.${questionIndex}` &&(
            <QuestionGhost/>
          )
          )}

          {/* Hovering below */}
          {ghostLocation === `${sectionIndex}.${questionIndex}Below` &&(
            <QuestionGhost/>
          )}
        </Stack>
      </div> 
    )
  }
  
  return (
    <>
      <form onSubmit={e =>
        {
          e.preventDefault(); 
          !formik.isValid && console.log("errors:", JSON.stringify(formik.errors));
           formik.isValid ? formik.handleSubmit(e) : formik.validateForm();
      }}
      >
        {formik.values.sections && formik.values.sections.length === 0 ? (
          <div style={{display: "flex", justifyContent: "center"}}>
           <Paper style={{display: "block", maxWidth: "fit-content", padding:"1rem", paddingTop:"0"}}>
             <QuestionTitle title="How are you starting your checklist?"/>
             <Stack spacing={1}>
               <Button onClick={handleCopyVersionClick}>Copy another Version</Button>
               <Button onClick={handleStartNewClick}>Start New</Button>
             </Stack>
           </Paper>
           <CopyVersionSelector/>
         </div>
        ) : (
        
          <Paper sx={{padding: "1rem", marginTop: "1.5rem"}}>
            <Stack sx={{padding:"1rem"}}>
              <Stack direction="row" sx={{display: "flex", justifyContent: "space-between"}}>
                <Typography variant="h5">{versionId === "new" ? "New" : formik.values.status === Status.Draft ? "Edit" : "View"} Version - {inspectionValues.label} {formik.values.versionLabel && ` - ${formik.values.versionLabel}`}</Typography>
                <Tooltip title="Go Back">
                    <IconButton onClick={handleBackClick}><ArrowBackIcon fontSize='large'/></IconButton>
                </Tooltip>
              </Stack>
              {formEditable ? (
                <Stack>
                  <QuestionTitle required title="Name" />
                  <TextField
                    id="versionLabel"
                    name="versionLabel"
                    value={formik.values.versionLabel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.versionLabel && Boolean(formik.errors.versionLabel)}
                    helperText={formik.touched.versionLabel && formik.errors.versionLabel}
                    variant="outlined"
                    size="small"
                    sx={{width: "20rem"}}
                  />
                  <QuestionTitle required title="Description" infoText="This description is the reason why this version has been created, not the purpose of the checklist."/>
                  <TextField
                    id="versionDescription"
                    name="versionDescription"
                    value={formik.values.versionDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.versionDescription && Boolean(formik.errors.versionDescription)}
                    helperText={formik.touched.versionDescription && formik.errors.versionDescription}
                    variant="outlined"
                    size="small"
                    sx={{width: "20rem"}}
                  />
                </Stack>
              ) : (
                <>
                  <QuestionTitle title="Description" infoText="This description is the reason why this version has been created, not the purpose of the checklist."/>
                  <Typography sx={{marginTop: "-1rem"}} variant="body2">{formik.values.versionDescription}</Typography>
                </>
                
              )

              }
              
              <Stack direction="row" spacing={4}>
              <div>
                <QuestionTitle title="Repeating on Component Set" infoText="Repeat the entire checklist per component within the component set."/>
                <Autocomplete
                  disablePortal
                  id="repeatingComponentSet"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => (option.label ? option.label : "")}
                  options={componentSetList}
                  size="small"
                  sx={{ width: "20rem"}}
                  value={formik.values.repeatingComponentSet ?? null}
                  onChange={(_, value: any) => {
                    formik.setFieldValue(
                      `repeatingComponentSet`,
                      value ? value : null
                    )
                    formik.setFieldValue(`repeatingComponentSetId`, value?.id ?? null)
                    if(value === null){
                      formik.setFieldValue(`userSelectedComponents`, null);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  disabled={!formEditable}
                  renderInput={(params) => (
                      <TextField
                        {...params}
                        name=""
                        size="small"
                        variant="outlined"
                      />
                  )}
                />
              </div>
              {(formik.values.repeatingComponentSet !== null) && (
                <div>
                  <QuestionTitle title="Repeating Checklist Type" required/>                   
                  <Select
                    id="repeatType"
                    name="repeatType"
                    value={formik.values.repeatType}
                    onChange={(event) => formik.setFieldValue(`repeatType`, event.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{width:"15rem"}}
                    disabled={!formEditable}
                  >
                    <MenuItem value={VersionRepeatType.AlwaysRepeat}>{VersionRepeatTypeString[VersionRepeatType.AlwaysRepeat]}</MenuItem>
                    <MenuItem value={VersionRepeatType.UserSelected}>{VersionRepeatTypeString[VersionRepeatType.UserSelected]}</MenuItem>
                    <MenuItem value={VersionRepeatType.UserSelectedOneOnly}>{VersionRepeatTypeString[VersionRepeatType.UserSelectedOneOnly]}</MenuItem>
                  </Select>
                </div>
              )}
            </Stack>
             <div>
                <QuestionTitle title="Force Section Workflow" required infoText="Toggled ON: Sections must be completed in order. Togged OFF: Sections can be completed out of order."/>                   
                  <Switch
                    name={`forceWorkflow`}
                    checked={Boolean(formik.values.forceWorkflow)}
                    onChange={() => formik.setFieldValue(`forceWorkflow`, !formik.values.forceWorkflow)}
                    disabled={!formEditable}
                  />
              </div>
            </Stack>
            <Stack direction="row" spacing={4} sx={{paddingBottom: "1rem", marginLeft: "1rem", display: "flex", alignItems: "center"}}>
              <FormControlLabel
                control={
                    <Switch
                        checked={
                            showChanges
                        }
                        onChange={() =>
                            setShowChanges(!showChanges)
                        }
                    />
                }
                label="Compare to Previous Version"
              />
              
              <Tooltip title={hideQuestions ? "Show Questions": "Hide Questions"}>
                <IconButton onClick={() => setHideQuestions(!hideQuestions)}>
                  {hideQuestions ? (
                    <VisibilityOffIcon />
                  ): (
                    <VisibilityIcon/>
                  )}
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Preview Checklist">
                <IconButton onClick={()=> setPreviewOpen((prevState) => !prevState)}>
                    <PreviewIcon fontSize="large"/>
                </IconButton>
              </Tooltip>
              
            </Stack>
            
          
          <Divider sx={{marginBottom: "2rem"}}/>
            
          {formik.values.sections && (
            <FormikProvider value={formik}>
              <FieldArray
                name="sections"
                render={(sectionArrayHelpers: FieldArrayRenderProps) => (
                  <Stack spacing={5}>
                    {formik.values.sections.map((_section, sectionIndex) => (
                        <Section key={sectionIndex} sectionIndex={sectionIndex} sectionArrayHelpers={sectionArrayHelpers} />
                    ))}
                  </Stack>
                )}
              />
            </FormikProvider>
          )}
            
          
          

        <Stack direction="row" spacing={2} sx={{padding: "1rem", display: "flex", justifyContent: "end"}}>
          <LoadingButton variant="contained" onClick={handleSaveClick} disabled={!formEditable || !formik.dirty} loading={loading}>Save</LoadingButton>
          <LoadingButton variant="contained" loading={loading} onClick={handlePublishClick}>Publish</LoadingButton>
        </Stack>
        {formErrors && (
          <div style={{display:"flex", justifyContent:"center", alignContent: "center"}}>
            <Paper sx={{padding: "1rem", width: "35rem", overflowWrap: "break-word", marginBottom: "2rem"}}>
              <Typography variant="h5" sx={{textAlign:"center"}}>Form Errors</Typography>
              {formErrors}
            </Paper>
          </div>
          
        )}
        <Typography></Typography>
        
      </Paper>
      )}
        
      </form>
      
      <DemoQRCode/>
      <PublishAlertDialog/>
      <ErrorHandlingSnackbar messageItem={messageItem} />
      
    </>
  );
}

export default InspectionsChecklistCreation;