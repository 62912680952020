import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { QuestionDto } from 'src/dtos/AwTypes';
import { InspectionChecklistResponseDto, InspectionChecklistQuestionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import { JournalEntryType } from 'src/dtos/Checklists/AttractionInspections/Journal.dto';
import { NameDto } from 'src/dtos/NameDto.dto';
import { GetQuestionStatus } from '../../../CalculateStatusesHelper';
import { Note } from '@mui/icons-material';
import { Paper, Stack, Grid, Typography, CircularProgress, Chip, Button, TextField } from '@mui/material';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import { Status } from 'src/dtos/Statuses';
import JournalEntryForm from './JournalEntryForm';
import ImpactsOperation from './YesOrNoQuestion/Components/ImpactsOperation';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';

type Props = {
    formik: FormikProps<InspectionChecklistResponseDto>;
    formikString: string;
    questionContent: QuestionDto;
    questionResponse: InspectionChecklistQuestionResponseDto;
    updateQuestionMutation: UseMutationResult<AxiosResponse<InspectionChecklistQuestionResponseDto, any>, Error, InspectionChecklistQuestionResponseDto, unknown>
    questionEditable: true | string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;
    attraction: NameDto;
    handleUpdateQuestion: (newValues: InspectionChecklistQuestionResponseDto) => void  
}
function NumberInputQuestion({formik, formikString, questionContent, questionResponse, updateQuestionMutation, questionEditable, setMessageItem, attraction, handleUpdateQuestion}: Props) {
    const [journalEntryFormOpen, setJournalEntryFormOpen] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [inputAnswer, setInputAnswer] = useState<number | null>(
        questionResponse.answer && !isNaN(parseFloat(questionResponse.answer))
        ? parseFloat(questionResponse.answer)
        : null
    )

    const validAnswerOrUnanswered = questionResponse.answer == null || (!isNaN(parseFloat(questionResponse.answer)) && questionContent.minAcceptableValue && questionContent.maxAcceptableValue && parseFloat(questionResponse.answer) >= questionContent.minAcceptableValue && parseFloat(questionResponse.answer) <= questionContent.maxAcceptableValue)

    const handleNumberInputSet = () => {
        if(questionEditable !== true){
            setMessageItem({errorMessage: questionEditable})
            return
        }

        handleUpdateQuestion({...questionResponse, answer: inputAnswer ? inputAnswer.toString() : null})
    }

    const handleJournalEntryClick = () => {
        setJournalEntryFormOpen(true)

        if(questionResponse.relatedJournalEntryId == null ){
            searchParams.set("relatedQuestionId", questionContent.id.toString())
            if(questionResponse.impactsOperation === true)
                searchParams.set("type", JournalEntryType.outOfService.toString())
            if(questionContent.relatedComponentId)
                searchParams.set("componentId", questionContent.relatedComponentId.toString())

            setSearchParams(searchParams)
        }
    }

    const onJournalEntryCreate = (journalEntryId: string) => {
        formik.setFieldValue(`${formikString}.relatedJournalEntryId`, journalEntryId)
        handleUpdateQuestion({...questionResponse, relatedJournalEntryId: journalEntryId})
    }

    const questionStatus = GetQuestionStatus(questionResponse, questionContent, formik.values.inspectedComponents, formik.values.selectedComponents ?? [])
    
    return (
        <Paper>
            <div style={{width:"100%", padding:"1rem", paddingTop:"0rem"}}>
                <Stack sx={{marginTop: "1rem"}}>
                    <Grid container>
                        <Grid item xs={10}>
                            {questionContent.relatedComponent && (
                                <div style={{width: "fit-content", padding: "2px 10px", borderRadius: "12px",   backgroundColor: "#f2f2f2"}}>
                                    <Typography variant="body2" sx={{color: "#333"}}>{questionContent.relatedComponent.label}</Typography>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={2} sx={{display:"flex", justifyContent:"right", height:"22px"}}>
                            {updateQuestionMutation.isSuccess ? (
                                <CloudDoneOutlinedIcon fontSize='small' color="success"/>
                            ): updateQuestionMutation.isError ? (
                                <CloudOffOutlinedIcon fontSize='small' color="error"/>
                            ): updateQuestionMutation.isPending ? (
                                <CircularProgress size="1rem" color="info" />
                            ): (
                                <CloudQueueIcon fontSize='small' sx={{color: "#cfcfcf"}} />
                            )}  
                        </Grid>

                    </Grid>
                    
                    <QuestionTitle
                        title={`${questionContent.number}. ${questionContent.label}`}
                        required
                        infoText={questionContent.additionalInformation}
                        marginTop='0.2rem'
                        fontSize={16}
                    />  
                </Stack>

                {questionStatus === Status.ComponentFailed && questionResponse.answer === null ? (
                    <Chip
                        label="Component Failed"
                        color="warning"
                    />
                ) : (
                    <>
                        <Stack spacing={2}>
                            <Stack direction="row">
                                <Typography sx={{marginRight:"6px"}}>
                                    Recorded Value:
                                </Typography>
                                <TextField
                                    id="answer"
                                    name="answer"
                                    type="number"
                                    value={inputAnswer ? inputAnswer.toString() : ""}
                                    onChange={(event) => setInputAnswer(parseFloat(event.target.value))}
                                    onBlur={handleNumberInputSet}
                                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                        if(event.key === "Enter"){
                                            const target = event.target as HTMLInputElement;
                                            target.blur();
                                        }
                                    }}
                                    variant="standard"
                                    size="small"
                                    sx={{width:"5rem"}}
                                />
                                <Typography>
                                    {questionContent.inputUnits}
                                </Typography>
                            </Stack>

                            {validAnswerOrUnanswered === false &&  (
                                <ImpactsOperation
                                    formik={formik}
                                    formikString={formikString}
                                    relatedComponent={questionContent.relatedComponent}
                                    questionResponse={questionResponse}
                                    questionEditable={questionEditable}
                                    setMessageItem={setMessageItem}
                                    handleUpdateQuestion={handleUpdateQuestion}
                                />
                            )}
                            <div style={{display:"flex", justifyContent:"end"}}>
                                <Button onClick={handleJournalEntryClick} sx={{gap:"0.5rem"}}>
                                    {questionResponse.relatedJournalEntryId ? (
                                        <Note/>
                                    ) : (
                                        <NoteOutlinedIcon color={validAnswerOrUnanswered === false ? "error" : "primary"}/>
                                    )}
                                    {validAnswerOrUnanswered === false ? (
                                        <Stack direction={"row"}>
                                            <Typography variant='caption' sx={{display:"flex", alignItems:"flex-end"}} color={questionResponse.relatedJournalEntryId ? "primary" : "error"}>Required Journal Entry</Typography>
                                            <span style={{color: 'red', verticalAlign: "top", marginLeft:"2px"}}>*</span>
                                        </Stack>
                                    ) : (
                                        <Typography variant='caption' sx={{display:"flex", alignItems:"flex-end"}}>Optional Journal Entry</Typography>
                                    )}
                                </Button>
                                <JournalEntryForm
                                    open={journalEntryFormOpen}
                                    handleClose={() => setJournalEntryFormOpen(false)}
                                    journalEntryId={questionResponse.relatedJournalEntryId}
                                    setMessageItem={setMessageItem}
                                    attraction={attraction}
                                    onCreate={onJournalEntryCreate}
                                />
                            </div>
                        </Stack>
                    </>
                )}
            </div>
        </Paper>
    )
}

export default NumberInputQuestion