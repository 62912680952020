import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Autocomplete, Box, Button, MenuItem, Paper, Select, Stack, Tab, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useMutation, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import SimpleEditor from 'src/components/SimpleEditor/SimpleEditor'
import { TrainingMemo, TrainingMemoType, TrainingMemoTypeString } from 'src/dtos/Training/TrainingMemo.dto'
import TrainingMemoService from 'src/Services/Training/TrainingMemoService'
import { guidRegex } from 'src/Utils/helperFunc'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService'

function TrainingMemoCreator() {
    const [searchParams, setSearchParams] = useSearchParams()
    const defaultTab = "Memo"
    const [openTab, setOpenTab] = useState(searchParams.get("openTab") ?? defaultTab);
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const {id} = useParams();
    const urlIdRegex = new RegExp(`/${id}$`)
    const navigate = useNavigate()

    //#region queries
    const trainingMemoQuery = useQuery({
        queryKey: ["trainingMemo", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id))
                return await TrainingMemoService.Get(id)
            return TrainingMemoService.GetDefaultValues()
        },
        initialData: TrainingMemoService.GetDefaultValues()
    })

    const trainingGroupsQuery = useQuery({
        queryKey: ["trainingGroupParentNames"],
        queryFn: async () => {
            return await TrainingGroupParentService.GetNames();
        }
    })

    const createMutation = useMutation({
        mutationFn: (values: TrainingMemo) => {
            return TrainingMemoService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Memo created successfully!"})

            //update URL
            navigate({pathname: window.location.pathname.replace(urlIdRegex, `/${data.data.id}`)})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: TrainingMemo}) => {
            return TrainingMemoService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Memo updated successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    useEffect(() => {
        if(trainingMemoQuery.isError){
            setMessageItem({error: trainingMemoQuery.error})
        }
        if(trainingGroupsQuery.isError)
            setMessageItem({error: trainingGroupsQuery.error})
    }, [trainingGroupsQuery.error, trainingGroupsQuery.isError, trainingMemoQuery.error, trainingMemoQuery.isError])
    //#endregion

    const save = async (values: TrainingMemo) => {
        if(id === "new"){
            //Create
            createMutation.mutate(values)
        } else if(id !== undefined && guidRegex.test(id)){
            //update
            updateMutation.mutate({id, values})
        }
    }

    const issue = async (values: TrainingMemo) => {
        await save(values)
        //prevent issue if saving.
    }

    const handleTabChange = (_event: React.SyntheticEvent, tabValue: string) => {
        setOpenTab(tabValue);
        if(tabValue === defaultTab) {
            searchParams.delete("openTab")
        } else {
            searchParams.set("openTab", tabValue)
        }
        setSearchParams(searchParams)
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: trainingMemoQuery.data,
        onSubmit: (values) => {
            issue(values)
        }
    })

    const handleChangeDetailChange = (content: string) => {

    }

    const handleChooseFileClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    }
    return (
        <>
            <Paper>
                <TabContext value={openTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Memorandum" value="Memo"/>
                            <Tab label="Team Members" value="TeamMembers" disabled={formik.values.dateIssued == null}/>
                            <Tab label="Team Acceptance" value="TeamAcceptance" disabled={formik.values.dateIssued == null}/>
                        </TabList>
                    </Box>
                    <TabPanel value="Memo">
                        <Stack spacing={1}>
                            <div>
                                <QuestionTitle title="Title" required/>
                                <TextField
                                    id="label"
                                    name="label"
                                    value={formik.values.label}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    size="small"
                                    sx={{width:"100%"}}
                                    error={formik.touched.label && Boolean(formik.errors.label)}
                                    helperText={formik.touched.label && formik.errors.label}
                                />
                            </div>
                            <div>
                                <QuestionTitle title="Date Effective" required/>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker 
                                    name="dateEffective"
                                    format='DD MMMM YYYY'
                                    value={formik.values.dateEffective ? dayjs(formik.values.dateEffective): null}
                                    onChange={(value) => {
                                        if(value){
                                            formik.setFieldValue("dateEffective", value.format("YYYY-MM-DD"))
                                        } else {
                                            formik.setFieldValue("dateEffective", null)
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: formik.touched.dateEffective && Boolean(formik.errors.dateEffective),
                                            helperText: formik.touched.dateEffective && formik.errors.dateEffective
                                        }
                                    }}
                                    sx={{width:"20rem"}}
                                />
                            </LocalizationProvider>
                            </div>
                            <div>
                                <QuestionTitle title="Type of Change" required/>
                                <Select
                                    id="type"
                                    name="type"
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    size="small"
                                    sx={{width:"20rem"}}
                                >
                                    <MenuItem value={TrainingMemoType.ProceduralChange}>{TrainingMemoTypeString[TrainingMemoType.ProceduralChange]}</MenuItem>
                                    <MenuItem value={TrainingMemoType.ProcedureReinforcement}>{TrainingMemoTypeString[TrainingMemoType.ProcedureReinforcement]}</MenuItem>
                                </Select>
                            </div>
                            <div>
                                <QuestionTitle title="Change Overview" required/>
                                <TextField
                                    id="changeOverview"
                                    name="changeOverview"
                                    value={formik.values.changeOverview}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    multiline
                                    maxRows={2}
                                    size="small"
                                    sx={{width:"100%"}}
                                    error={formik.touched.changeOverview && Boolean(formik.errors.changeOverview)}
                                    helperText={formik.touched.changeOverview && formik.errors.changeOverview}
                                />
                            </div>
                            <div>
                                <QuestionTitle title="Change Detail" required/>
                                <SimpleEditor
                                    content={formik.values.changeDetail}
                                    onFocus={() => console.log("focus")}
                                    onBlurSaveContent={handleChangeDetailChange}
                                />
                            </div>
                            <div>
                                <QuestionTitle title="SOP Reference"/>
                                <Button onClick={handleChooseFileClick}>
                                    <Stack sx={{display:"flex", alignItems:"center"}}>
                                        <AttachFileIcon fontSize='large'/>   
                                        <Typography variant='caption'>Choose file</Typography>
                                    </Stack>
                                </Button>
                                <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    style={{display:"none"}}
                                    multiple
                                    onChange={handleFileChange}   
                                />
                            </div>
                            <div>
                                <QuestionTitle title="Intent of Change" required/>
                                <TextField
                                    id="intentOfChange"
                                    name="intentOfChange"
                                    value={formik.values.intentOfChange}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    multiline
                                    maxRows={2}
                                    size="small"
                                    sx={{width:"100%"}}
                                    error={formik.touched.intentOfChange && Boolean(formik.errors.intentOfChange)}
                                    helperText={formik.touched.intentOfChange && formik.errors.intentOfChange}
                                />
                            </div>
                            <div>
                                <QuestionTitle title="Affected Training Groups" required/>
                                <Autocomplete
                                    id="affectedTrainingGroups"
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => (option.label ? option.label : "")}
                                    options={trainingGroupsQuery.data ?? []}
                                    multiple
                                    value={formik.values.affectedTrainingGroups}
                                    onChange={(e, value) => {
                                        formik.setFieldValue("affectedTrainingGroups", value)
                                    }}
                                    size={"small"}
                                    sx={{width:"100%"}}
                                    renderInput={(params) => 
                                        <TextField {...params}/>
                                    }
                                />
                            </div>
                        </Stack>
                    </TabPanel>
                    <TabPanel value="TeamAcceptance">
                        Memo has not been issued yet!
                    </TabPanel>

                </TabContext>
            </Paper>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TrainingMemoCreator