import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "./components/common/layouts/AdminLayout";
import UserLayout from "./components/common/layouts/UserLayout";
import PageNotFound from "./pages/UtilityPages/PageNotFound/PageNotFound";
import {adminAppRoutes, completedDocumentAppRoutes, userAppRoutes, } from "./routes";
import {AuthenticatedTemplate,MsalProvider,UnauthenticatedTemplate,useMsal,} from "@azure/msal-react";
import { loginRequest } from "./config/authConfig";
import {Avatar ,Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Stack, Typography,} from "@mui/material";
import { IPublicClientApplication } from "@azure/msal-browser";
import assets from "./assets";
import LandingPage from "./pages/UtilityPages/LandingPage/LandingPage";
import { QueryClient } from "@tanstack/react-query";
import CompletedDocumentLayout from "./components/common/layouts/CompletedDocumentLayout";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { GetLoginTime, LogoutAllUsers, RefreshToken, StoreLoginTime } from "./Utils/authUtils";

const LOGOUT_AFTER = 3 * 60 * 60 * 1000; //time to logout after login in milliseconds (if token is expired)
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 60 * 24, //24 hours
        },
        mutations: {
            retry: 3,
            gcTime: 1000 * 60 * 60,
        },
    },
});

const persister = createSyncStoragePersister({
    storage: window.localStorage,
});

const MainContent = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const navigate = useNavigate();
    const location = useLocation();
    const [sessionTimeoutOpen, setSessionTimeoutOpen] = useState(false);

    const handleLoginClick = () => {
        // Store the current location before redirecting
        localStorage.setItem("redirectLocation", location.pathname);

        instance.loginRedirect({
                ...loginRequest,
                prompt: "select_account",
                redirectStartPage: "",
            })
            .catch((error) => console.log(error));
        //set login time
        StoreLoginTime()
    };

    useEffect(() => {
        const redirectLocation = localStorage.getItem("redirectLocation");
        // Check if there's a stored redirect location
        if (activeAccount && redirectLocation) {
            // Redirect the user to the stored location after successful login
            navigate(redirectLocation);
            localStorage.removeItem("redirectLocation");
        }
    }, [activeAccount, navigate]);

    function SessionTimeoutExtend() {
        const handleLogoutClick = async () => {
            await LogoutAllUsers();
        };

        const handleRefreshToken = async () => {
           await RefreshToken()
           setSessionTimeoutOpen(false)
        };
        return (
            <Dialog
                open={sessionTimeoutOpen}
                onClose={() => setSessionTimeoutOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Session timeout
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`You have been inactive and will be logged out soon. Do you want to continue your session?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogoutClick}>{`Logout`}</Button>
                    <Button variant="contained" onClick={handleRefreshToken}>
                        {`Extend session`}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    useEffect(() => {
        const checkSessionExpiry = async () => {
            const accounts = instance.getAllAccounts();
            const loginTime = GetLoginTime();
            if (accounts.length > 0) {
                const activeAccount = accounts[0];
                const idTokenClaims = activeAccount.idTokenClaims;
                if (
                    idTokenClaims === undefined ||
                    idTokenClaims.exp === undefined ||
                    loginTime === null
                ) {
                    return;
                }
                const expiration = idTokenClaims.exp * 1000; // Convert to milliseconds
                const now = new Date().getTime();

                const fifteenMinutes = 15 * 60 * 1000
                //session is within 15 minutes of logout time
                if (now >= expiration - fifteenMinutes && now <= expiration && now >= loginTime + LOGOUT_AFTER - fifteenMinutes) {
                    setSessionTimeoutOpen(true);
                } else {
                    setSessionTimeoutOpen(false);
                }

                if (now >= expiration) {
                    console.log("Token has expired");

                    if (now - loginTime >= LOGOUT_AFTER) {
                        console.log("Session expired and user has been logged in for more than the specified logout after time, logging out");
                        await LogoutAllUsers();
                    }

                    if (now - loginTime <= LOGOUT_AFTER && now >= loginTime + LOGOUT_AFTER - 2.75 * 60 * 60 * 1000) {
                        setSessionTimeoutOpen(true);
                    } else {
                        setSessionTimeoutOpen(false);
                    }
                }
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                checkSessionExpiry();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        const intervalId = setInterval(checkSessionExpiry, 1000 * 60 * 5); // Check every 5 minutes
        // Cleanup the event listener and interval
        return () => {
            clearInterval(intervalId);
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
        };
    }, [instance]);

    return (
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister, maxAge: 1000 * 60 * 60 * 24 }}
        >
            <div className="App">
                <AuthenticatedTemplate>
                    {activeAccount ? (
                        <Routes>
                            <Route path="/" element={<LandingPage />} />

                            {/* Admin protected routes */}
                            <Route path="/admin/" element={<AdminLayout />}>
                                {adminAppRoutes}
                            </Route>

                            {/* user routes */}
                            <Route path="/user/" element={<UserLayout />}>
                                {userAppRoutes}
                            </Route>

                            {/* completedDocument routes */}
                            <Route
                                path="/completed/"
                                element={<CompletedDocumentLayout />}
                            >
                                {completedDocumentAppRoutes}
                            </Route>

                            {/* catch all (page not found) */}
                            <Route path="/*" element={<PageNotFound />} />
                        </Routes>
                    ) : null}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <Paper
                            sx={{ padding: "2rem", blockSize: "fit-content" }}
                        >
                            <Stack
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <Avatar
                                    src={assets.images.logo}
                                    sx={{
                                        width: "8rem",
                                        height: "8rem",
                                        marginBottom: "1rem",
                                    }}
                                />
                                <Typography variant="h3" gutterBottom>
                                    AW Hub
                                </Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        onClick={handleLoginClick}
                                        variant="contained"
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        Sign in with Microsoft
                                    </Button>
                                </div>
                            </Stack>
                        </Paper>
                    </div>
                </UnauthenticatedTemplate>
            </div>
            <SessionTimeoutExtend />
        </PersistQueryClientProvider>
    );
};

function App({ instance }: { instance: IPublicClientApplication }) {
    return (
        <MsalProvider instance={instance}>
            <MainContent />
        </MsalProvider>
    );
}

export default App;
