import { BaseModel } from "../BaseModel.dto";
import { FileMetadata } from "../FileMetadata.dto";
import { NameDto } from "../NameDto.dto";

export interface TrainingMemo extends BaseModel {
    id: string;
    label: string;
    dateEffective?: Date;
    dateIssued?: Date;
    affectedTrainingGroups: NameDto[]
    type: TrainingMemoType;
    changeOverview?: string;
    changeDetail: string;
    sopReference?: FileMetadata;
    intentOfChange?: string;
}

export enum TrainingMemoType {
    ProceduralChange, 
    ProcedureReinforcement
}

export const TrainingMemoTypeString = [
    "Procedural Change",
    "Procedure Reinforcement"
]