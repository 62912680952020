import React from 'react'
import { Link } from 'react-router-dom'

function TrainingMemos() {
  return (
    <div>
        TrainingMemos
        <Link to={"new"}>
        
            New training Memo
        </Link>

    </div>
  )
}

export default TrainingMemos