import { FormikProps } from 'formik';
import React, { memo } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { QuestionDto, QuestionType } from 'src/dtos/AwTypes';
import { InspectionChecklistResponseDto, InspectionChecklistQuestionResponseDto, InspectedComponentStatus } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import AlertQuestion from './Components/AlertQuestion';
import YesOrNoQuestion from './Components/YesOrNoQuestion/YesOrNoQuestion';
import { NameDto } from 'src/dtos/NameDto.dto';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Status } from 'src/dtos/Statuses';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import { GetQuestionStatus } from '../../CalculateStatusesHelper';
import NumberInputQuestion from './Components/NumberInputQuestion';

type Props = {
    formik: FormikProps<InspectionChecklistResponseDto>;
    formikString: string;
    questionContent: QuestionDto;
    questionResponse: InspectionChecklistQuestionResponseDto;
    questionEditable: true | string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;
    attraction: NameDto
    demoMode?: Boolean;
    sectionRepeatTypeGrouped?: boolean;
}

function areEqual(prevProps: Props, nextProps: Props) {
    return (
        prevProps.questionContent === nextProps.questionContent &&
        prevProps.questionResponse === nextProps.questionResponse &&
        prevProps.questionEditable === nextProps.questionEditable && 
        prevProps.formik.values.inspectedComponents === nextProps.formik.values.inspectedComponents &&
        prevProps.formik.values.selectedComponents === nextProps.formik.values.selectedComponents
    )
}
function Question({formik, formikString, questionContent, questionResponse, questionEditable, setMessageItem, attraction, demoMode, sectionRepeatTypeGrouped}: Props) {
    const queryClient = useQueryClient()
    const {responseId} = useParams(); 
    
    //#region queries
    const updateQuestionMutation = useMutation({
        mutationKey: ["UpdateQuestion", questionResponse.id],
        mutationFn: async (answer: InspectionChecklistQuestionResponseDto) => {
            queryClient.cancelQueries({queryKey: ["checklistResponse", responseId], exact: true})
            return await InspectionChecklistResponseService.UpdateQuestion(questionResponse.id, answer)
        },
        onSuccess: () => {
            if(responseId){
                queryClient.invalidateQueries({queryKey: ["checklistResponse", responseId], exact: true});
            }
        },
    })

    const updateInspectedComponentMutation = useMutation({
        mutationFn: async ({responseId, componentId, status}: {responseId: string, componentId: number, status: InspectedComponentStatus}) => {
            queryClient.cancelQueries({queryKey: ["checklistResponse", responseId], exact: true})
            return await InspectionChecklistResponseService.UpdateInspectedComponent(responseId, componentId, status)
        }
    })

    //#endregion

    const handleUpdateQuestion = (newValues: InspectionChecklistQuestionResponseDto) => {
        //Update timeAnswered
        const questionStatus = GetQuestionStatus(newValues, questionContent, formik.values.inspectedComponents, formik.values.selectedComponents??[])
        if((newValues.timeAnswered === undefined || newValues.timeAnswered === null) && (questionStatus === Status.Failed || questionStatus === Status.Passed || questionStatus === Status.ComponentFailed)){
            newValues.timeAnswered = new Date()
        } else {
            //if the question is unanswered clear time answered
            newValues.timeAnswered = undefined;
        }

        formik.setFieldValue(formikString, newValues)

        if (questionContent.relatedComponentId){
            const inspectedComponentIndex = formik.values.inspectedComponents.findIndex(ic => ic.component.id === questionContent.relatedComponentId)
            const newQuestionStatus = GetQuestionStatus(newValues, questionContent, formik.values.inspectedComponents, formik.values.selectedComponents ?? [])

            let newComponentStatus = InspectedComponentStatus.notInspected;
            if( newQuestionStatus === Status.ComponentFailed)
                newComponentStatus = InspectedComponentStatus.failed
            else if (newQuestionStatus === Status.Passed ) 
                newComponentStatus = InspectedComponentStatus.passed
             
            if(inspectedComponentIndex !== -1 && formik.values.inspectedComponents[inspectedComponentIndex].status !== newComponentStatus){
                formik.setFieldValue(`inspectedComponents[${inspectedComponentIndex}].status`, newComponentStatus)
                if(!demoMode && responseId){
                    updateInspectedComponentMutation.mutate({responseId: responseId, componentId: questionContent.relatedComponentId, status: newComponentStatus})
                }
            }
        }
        
        if(!demoMode){
            updateQuestionMutation.mutateAsync(newValues)
        }

    }

    if(questionContent.type === QuestionType.YesOrNo){
        return (
            <YesOrNoQuestion
                formik={formik}
                formikString={formikString}
                questionContent={questionContent}
                questionResponse={questionResponse}
                questionEditable={questionEditable}
                updateQuestionMutation={updateQuestionMutation}
                setMessageItem={setMessageItem}
                attraction={attraction}
                handleUpdateQuestion={handleUpdateQuestion}
                sectionRepeatTypeGrouped={sectionRepeatTypeGrouped}
            />
        )
    } 

    if(questionContent.type === QuestionType.Number){
        return (
            <NumberInputQuestion
                formik={formik}
                formikString={formikString}
                questionContent={questionContent}
                questionResponse={questionResponse}
                questionEditable={questionEditable}
                updateQuestionMutation={updateQuestionMutation}
                setMessageItem={setMessageItem}
                attraction={attraction}
                handleUpdateQuestion={handleUpdateQuestion}
            />
        )
    }
    
    return (
        <AlertQuestion
            questionTitle={questionContent.label}
        />
    )
    
}

export default memo<Props>(Question, areEqual)